import { BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import VueCompositionApi, {
  Ref,
  SetupContext,
  provide,
  ref,
} from '@vue/composition-api';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import 'wicg-inert';

declare global {
  interface Window {
    consentFunctional: any;
  }
}

// App main
const main = async () => {
  await import(
    /* webpackMode: "lazy", webpackChunkName: "lazysizes" */ 'lazysizes'
  );
  const { default: smoothscroll } = await import(
    /* webpackMode: "lazy", webpackChunkName: "smoothscroll" */ 'smoothscroll-polyfill'
  );
  // Async load the vue module
  const { default: Vue } = await import(
    /* webpackMode: "lazy", webpackChunkName: "vue" */ 'vue'
  );
  const { useBreakpointTailwindCSS } = await import(
    /* webpackMode: "lazy", webpackChunkName: "vue-composable" */ 'vue-composable'
  );
  const { default: tailwindConfig } = await import(
    /* webpackMode: "lazy", webpackChunkName: "config" */ '../../tailwind.config.js' as any
  );
  const { default: NavigationComponent } = await import(
    /* webpackMode: "lazy", webpackChunkName: "navigation" */ './components/NavigationComponent.vue'
  );
  const { default: PageSwiperComponent } = await import(
    /* webpackMode: "lazy", webpackChunkName: "page-swiper" */ './components/PageSwiperComponent.vue'
  );
  const { default: CookieDisclaimer } = await import(
    /* webpackMode: "lazy", webpackChunkName: "cookie" */ './components/CookieDisclaimer.vue'
  );
  const { default: toggle } = await import(
    /* webpackMode: "lazy", webpackChunkName: "toggle" */ './directives/toggle'
  );
  const { default: lazyLoadComponent } = await import(
    /* webpackMode: "lazy", webpackChunkName: "lazy-load-component" */ '../utils/lazy-load-component'
  );
  const { default: fragmentTypes } = await import(
    /* webpackMode: "lazy", webpackChunkName: "fragment-types" */ './queries/fragmentTypes.json'
  );

  smoothscroll.polyfill();

  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://b8e8c79c40164e9080f5f019866d5f3d@sentry.urbantrout.io/4',
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      Vue,
      integrations: [new BrowserTracing(), new Sentry.Replay()],
      tracesSampleRate: 0.2,
    });
  }

  Vue.config.productionTip = false;

  Vue.use(VueCompositionApi);

  Vue.directive('toggle', toggle);

  const httpLink = createHttpLink({
    uri: '/api',
  });

  const introspectionQueryResultData: any = fragmentTypes;
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });
  const cache = new InMemoryCache({ fragmentMatcher });

  const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
  });

  // Create our vue instance
  const vm = new Vue({
    el: '#page-container',
    components: {
      NavigationComponent,
      PageSwiperComponent,
      ImageSwiperComponent: lazyLoadComponent({
        componentFactory: () =>
          import(
            /* webpackMode: "lazy", webpackChunkName: "image-swiper" */ './components/ImageSwiperComponent.vue'
          ),
        loading: null,
      }),
      IndexComponent: lazyLoadComponent({
        componentFactory: () =>
          import(
            /* webpackMode: "lazy", webpackChunkName: "index" */ './components/IndexComponent.vue'
          ),
        loading: null,
      }),
      SummaryComponent: lazyLoadComponent({
        componentFactory: () =>
          import(
            /* webpackMode: "lazy", webpackChunkName: "summary" */ './components/SummaryComponent.vue'
          ),
        loading: null,
      }),
      SwitcherComponent: lazyLoadComponent({
        componentFactory: () =>
          import(
            /* webpackMode: "lazy", webpackChunkName: "switcher" */ './components/SwitcherComponent.vue'
          ),
        loading: null,
      }),
      AudioComponent: lazyLoadComponent({
        componentFactory: () =>
          import(
            /* webpackMode: "lazy", webpackChunkName: "audio" */ './components/AudioComponent.vue'
          ),
        loading: null,
      }),
      CookieDisclaimer,
    },

    setup(props, context: SetupContext): any {
      const activeSlug = ref('');
      const transitioningSlideSlugs: Ref<string[]> = ref([]);

      provide(DefaultApolloClient, apolloClient);
      provide('activeSlug', activeSlug);
      provide('transitioningSlideSlugs', transitioningSlideSlugs);

      context.root.$on('activeSlug', (value: string) => {
        activeSlug.value = value;
      });

      context.root.$on('transitioningSlideSlugs', (value: string[]) => {
        transitioningSlideSlugs.value = value;
      });

      const handleVideoEnded = () => {
        context.root.$emit('slideNext');
      };

      return {
        ...useBreakpointTailwindCSS(tailwindConfig),
        activeSlug,
        transitioningSlideSlugs,
        handleVideoEnded,
      };
    },
  });

  return vm;
};

// Execute async function
main().then(vm => {});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
